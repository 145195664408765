



















































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import BillingInvoiceTableItem from "@/components/billing/BillingInvoiceTableItem.vue";
import {mapActions, mapGetters} from "vuex";
import {VenueData} from "@/types";

export default Vue.extend({
  name: "billing" as string,

  components: {
    Layout,
    "search-bar": SearchBar,
    "billing-invoice-table-item": BillingInvoiceTableItem,
  },

  data() {
    return {
      title: "Billing",
      currentPage: 1,
      perPage: 10
    };
  },

  async mounted(): Promise<void> {
    await this.checkIfAdmin()
    await this.setVenues({fetchRatings: false, name: ''})
    await this.setInvoices({ currentPage: this.currentPage, invoiceNumber: ''});
  },

  computed: {
    ...mapGetters("billingModule", {
      invoices: "GET_INVOICES",
      totalInvoicesCount: "GET_INVOICES_TOTAL_COUNT"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    totalInvoices(): number {
      return this.totalInvoicesCount;
    },
    sortedInvoices(){
      if(!this.invoices.length) return "";
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let sortedInvoices = this.invoices.sort((invoice1, invoice2) => {return invoice2.id - invoice1.id} )
      return sortedInvoices
    },
    invoicesList(): Array<VenueData> {
      return this.sortedInvoices
    },
  },

  methods: {
    ...mapActions("billingModule", {
      setInvoices: "FETCH_INVOICES"
    }),
    ...mapActions('venueModule', {
      setVenues: "FETCH_VENUES"
    }),
    ...mapActions("userModule", {
      checkIfAdmin: "CHECK_IF_ADMIN"
    }),
    resetPagination(): void {
      this.currentPage = 1;
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setInvoices({ currentPage: this.currentPage, invoiceNumber: ''});
        }
      }
    },
    currentPage: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setInvoices({ currentPage: this.currentPage, invoiceNumber: ''});
        }
      }
    }
  }
});
