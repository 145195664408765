



























import Vue, {PropType} from "vue";
import {InvoiceData} from "@/types";
import {formatUnitPrice} from "@/utils/helpers";
import {mapActions} from "vuex";

export default Vue.extend({
    name: "billing-invoice-table-item" as string,

    props: {
      data: Object as PropType<InvoiceData>
    },

    computed: {
      invoicePrice(): string {
        if (!this.data.amount || this.data.amount === "0") return "0 €";
        return formatUnitPrice(this.data.amount) + " €";
      },
      invoicePaymentType(): string {
        return this.data.paymentType === 1 ? this.$t('Credit/Debit card') : this.$t('Invoice');
      },
      subscriptionPeriodDate(): string {
        return this.formatDate(this.data.startSubscriptionPeriodDate) + " - " + this.formatDate(this.data.endSubscriptionPeriodDate);
      }
    },

    methods: {
      ...mapActions("billingModule", {
        downloadInvoice: "DOWNLOAD_INVOICE"
      }),
      async downloadInvoicePdf(id: number): Promise<void> {
        await this.downloadInvoice(id);
      },
      formatDate(value: string): string {
        const date = new Date(Number(value) * 1000);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        return ('0' + day).slice(-2) + '.' + ('0' + (month)).slice(-2) + '.' + year;
      },
      statusVariant(status: string): string {
        switch (status) {
          case "paid":
            return "success";
          case "cancelled":
            return "cancelled";
          case "trialing":
            return "warning";
          case "active":
            return "primary";
          case "open":
            return "info";
          case "credit note":
            return "credit-note";
          case "due":
            return "due";
          case "uncollectible":
            return "uncollectible";
          case "past due":
            return "past-due";
          default:
            return "";
        }
      }
    }
  })
